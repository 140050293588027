<template>
<div class="box-white-fiche" v-if="show_header_bp(1)">
    <div class="row-column">
        <div class="col-child" v-if="$i18n.locale == 'fr' && data['bp_presentation']">
            <div class="status-box" >
                <h3>{{$t('PRÉSENTATION')}}</h3>
                <p >{{data.bp_presentation}}</p>
            </div>
        </div>
        <div class="col-child" v-if="$i18n.locale == 'en' && data['bp_presentation_en']">
            <div class="status-box" >
                <h3>{{$t('PRÉSENTATION')}}</h3>
                <p >{{data.bp_presentation_en}}</p>
            </div>
        </div>
        <div class="col-child" v-if="$i18n.locale == 'de' && data['bp_presentation_de']">
            <div class="status-box" >
                <h3>{{$t('PRÉSENTATION')}}</h3>
                <p >{{data.bp_presentation_de}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.bp_dci">
            <div class="status-box" >
                <h3>{{$t('ACTIVE INGREDIENT')}}</h3>
                <p>{{data.bp_dci}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_exploitant">
            <div class="status-box" >
                <h3>{{$t('EXPLOITANT')}}</h3>
                <p>{{data.bp_exploitant}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_atc_pro">
            <div class="status-box" >
                <h3>{{$t('ATC')}}</h3>
                <p>{{data.bp_atc_pro}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="role_admin.includes(user.user_role_id) && data.date_amm_archivee">
            <div class="status-box" >
                <h3>{{$t('AMM archivée le')}}</h3>
                <p>{{ DDMMMYY(data.date_amm_archivee)}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="role_admin.includes(user.user_role_id) && data.date_amm_abrogee">
            <div class="status-box" >
                <h3>{{$t('AMM abrogée le')}}</h3>
                <p>{{ DDMMMYY(data.date_amm_abrogee)}}</p>
            </div>
        </div>
        <PDFModifyButton agency="BoitePresentation"/>
    </div> 
</div>

<div class="box-white-fiche" v-if="show_header_bp(2)">
    <div class="row-column">
        <div class="col-child"  v-if="data.bp_cip7">
            <div class="status-box" >
                <h3>{{$t('CIP7')}}</h3>
                <p>{{data.bp_cip7}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_cip13">
            <div class="status-box" >
                <h3>{{$t('CIP13')}}</h3>
                <p v-if="data.amm_archivee" >{{$t('AMM archivée')}}</p>
                <p v-if="data.amm_abrogee" >{{$t('AMM abrogée')}}</p>
                <p v-if="data.amm_retiree" >{{$t('AMM retirée')}}</p>
                <p v-if="data.amm_suspendue" >{{$t('AMM suspendue')}}</p>
                <p>{{data.bp_cip13}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_ucd">
            <div class="status-box" >
                <h3>{{$t('UCD7')}}</h3>
                <p>{{data.bp_ucd}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_ucd13">
            <div class="status-box" >
                <h3>{{$t('UCD13')}}</h3>
                <p>{{data.bp_ucd13}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="role_admin.includes(user.user_role_id) && data.bp_cis">
            <div class="status-box" >
                <h3>{{$t('CIS')}}</h3>
                <p>{{data.bp_cis}}</p>
            </div>
        </div>
    </div>
</div>
<div class="box-white-fiche" v-if="show_header_bp(3)">
    <div class="row-column">   
        <div class="col-child"  v-if="data.bp_type_amm">
            <div class="status-box" >
                <h3>{{$t("Type d'AMM")}}</h3>
                <p>{{$t(data.bp_type_amm)}} </p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_parallel_import">
            <div class="status-box" >
                <h3>{{$t("Importation parallèle")}}</h3>
                <p>{{$t("oui")}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.bp_unite">
            <div class="status-box" >
                <h3>{{$t("UNITÉS")}}</h3>
                <p>{{data.bp_unite}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.boite_presentation_forme_galenique_lists && data.boite_presentation_forme_galenique_lists.length > 0">
            <div class="status-box" >
                <h3>{{$t("FORME GALÉNIQUE")}}</h3>
                <p v-for="(item2,key2 ) in data.boite_presentation_forme_galenique_lists" :key=key2>{{check_empty_with_locale(item2.text)}}</p>
            </div>
        </div>
    </div>
</div>
  
</template>

<script>
import PDFModifyButton from './PDFModifyButton.vue'
import {check_empty_with_locale, DDMMMYY} from '../../../utils'
export default {
    name : "HeaderForBoitePresentation",
    components : {
        PDFModifyButton
    },
    computed : {
        role_admin(){
            return [1,3,10]
        },
        data(){
            return this.$store.getters['detail/data']
        },
        user(){
            return this.$store.getters['auth/user']
        },
    },
    methods : {
        check_empty_with_locale,
        DDMMMYY,
        show_header_bp(index){
            if(index===1){
                return (this.$i18n.locale == 'fr' && this.data['bp_presentation']) ||
                        (this.$i18n.locale == 'en' && this.data['bp_presentation_en']) ||
                        (this.$i18n.locale == 'de' && this.data['bp_presentation_de']) ||
                        (this.data.bp_dci) ||
                        (this.data.bp_exploitant) ||
                        (this.data.bp_atc_pro) ||
                        (this.role_admin.includes(this.user.user_role_id) && this.data.date_amm_archivee) ||
                        (this.role_admin.includes(this.user.user_role_id) && this.data.date_amm_abrogee)
            }
            else if(index===2){
                return this.data.bp_cip7 || this.data.bp_cip13 || this.data.bp_ucd || this.data.bp_ucd13 || (this.role_admin.includes(this.user.user_role_id) && this.data.bp_cis)

            }
            else if(index===3){
                return this.data.bp_type_amm || this.data.bp_parallel_import || this.data.bp_unite || 
                                (this.data.boite_presentation_forme_galenique_lists && this.data.boite_presentation_forme_galenique_lists.length > 0)
            }
        },
    }
}
</script>

<style>

</style>
